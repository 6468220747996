@import './modal.css';
@import './button.css';

.container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #000000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}

.title {
    text-align: center;
    color: #39FF14;
    margin-bottom: 20px;
}

.subheading {
    text-align: center;
    color: #39FF14;
    font-size: 16px;
    margin-bottom: 20px;
}

.message {
    margin-bottom: 20px;
    color: #39FF14;
    font-size: 18px;
    text-align: center;
}

.results {
    margin-bottom: 20px;
    color: #39FF14;
    font-size: 16px;
    text-align: left;
}

.button-group {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.typing-effect {
    color: #39FF14;
    font-size: 18px;
    text-align: center;
    white-space: normal;
}