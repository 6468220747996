body {
    font-family: 'Courier New', Courier, monospace;
    margin: 0;
    padding: 0;
    background-color: #1f414a;
    color: #39FF14;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}