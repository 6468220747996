.App {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.App-header {
    background-color: #000000;
    width: 80%;
    max-width: 600px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: #39FF14;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: default;
}

.typing-text {
    cursor: pointer;
}