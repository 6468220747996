@import './modal.css';
@import './form.css';
@import './button.css';

.container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #000000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}

.title {
    text-align: center;
    color: #39FF14;
    margin-bottom: 20px;
}

.camera-serial-item {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.camera-buttons {
    display: flex;
    gap: 10px;
}

.primary-button {
    margin-right: 10px;
}

.button-group {
    text-align: center;
    margin-top: 20px;
}

.subheading {
    text-align: center;
    color: #39FF14;
    font-size: 16px;
    margin-bottom: 20px;
}