.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #1f414a;
    color: #39FF14;
    padding: 20px;
    border: 4px solid #39FF14;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-family: 'Courier New', Courier, monospace;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #39FF14;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.modal-body {
    padding: 10px 0;
    text-align: center;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.modal-close-button {
    background: none;
    border: none;
    color: #39FF14;
    font-size: 1.5em;
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace;
}

.modal-bottom-close-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: #1f414a;
    border: 2px solid #39FF14;
    color: #39FF14;
    padding: 10px 20px;
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1em;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.modal-body img {
    max-width: 100%;
    max-height: 100%;
    border: 2px solid #39FF14;
}

.modal.results-modal .modal-content {
    width: 600px;
    /* Set a fixed width */
    max-width: 90%;
    height: 330px;
    /* Set a fixed height */
    max-height: 90%;
    overflow-y: auto;
}

.typing-effect p {
    white-space: normal;
    /* Ensure the text wraps */
}