form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

label {
    font-weight: bold;
    color: #39FF14;
}

input {
    padding: 10px;
    border: 1px solid #39FF14;
    border-radius: 4px;
    background-color: #000000;
    color: #39FF14;
}

select {
    padding: 10px;
    border: 1px solid #39FF14;
    border-radius: 4px;
    background-color: #000000;
    color: #39FF14;
}