.typing-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #39FF14;
    text-align: center;
    background-color: #000;
    cursor: pointer;
    border-radius: 10px;
}

.typing-text h1 {
    margin-bottom: 20px;
}

.typing-text h2 {
    font-size: 24px;
}