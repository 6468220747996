@import './modal.css';
@import './form.css';
@import './button.css';

.container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #000000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}

.title {
    text-align: center;
    color: #39FF14;
    margin-bottom: 20px;
}